@font-face {
  font-family: "SegoeUI";
  src: url("./fonts/Segoe-UI-400.ttf") format("truetype"),
    url("./fonts/Segoe-UI-Black-900.ttf") format("truetype"),
    url("./fonts/Segoe-UI-Semibold-600.ttf") format("truetype"),
    url("./fonts/Segoe-UI-Gras-700.ttf") format("truetype"),
    url("./fonts/Segoe-UI-Italique-400.ttf") format("truetype"),
    url("./fonts/Segoe-UI-Semibold-Italic-600.ttf"),
    url("./fonts/Segoe-UI-Gras-Italique-700.ttf"),
    url("./fonts/Segoe-UI-Black-Italic-900.ttf");
}

* {
  font-family: "SegoeUI";
}

.nav_bg {
  background-color: #f5eefe;
  box-shadow: 0px 0px 2px #000000;
}

.purpal-color {
  color: #a29ac8;
}
.white-color {
  color: #ffffff;
}

.navbar {
  .navbar-toggler {
    background: #9990c5;
    border: none;
    border-radius: 0;
  }
  .navbar-collapse {
    flex-direction: row-reverse;
    @media screen and (max-width: 767px) {
      border-top: 1px solid #c8c5d4;
    }
    .navbar-nav {
      .nav-link {
        text-transform: uppercase;
        font-size: 13px;
        color: #1d1d1d;
        font-weight: 600;
        position: relative;
        margin-left: 10px;
        @media screen and (max-width: 991px) {
          margin-left: 0px;
        }
        &.active {
          color: #846ee4;
          &::after {
            content: "";
            position: absolute;
            height: 3px;
            width: 40px;
            background-color: #9990c5;
            bottom: 0;
            left: 0;
            margin: 0 auto;
            right: 0;
            @media screen and (max-width: 991px) {
              margin: 0;
            }
          }
        }
      }
    }
  }
}

.TopHeading {
  background: #1d1d1d;
  margin-top: 70px;
  color: #ffffff;
  padding: 20px 0px;
  h2 {
    font-size: 25px;
  }

  [class*="col-"] {
    @media screen and (min-width: 992px) {
      padding-left: 0;
    }
  }
}

.FaqList {
  padding: 40px 0px;
  .accordion {
    .card {
      .card-header {
        background: #bdb8d8;
        padding: 10px 20px;
        font-size: 14px;
        font-weight: bold;
        text-transform: uppercase;
        cursor: pointer;
        position: relative;
        &::after {
          position: absolute;
          font-family: FontAwesome;
          content: "\f107";
          right: 20px;
        }
      }
      .collapse {
        .card-body {
          position: relative;
          padding: 20px;
        }
      }
    }
  }
}

.PrivacyContent {
  padding: 40px 0px;
}

#home {
  position: relative;
  .carousel {
    margin-top: 69px;
    .carousel-indicators {
      margin-bottom: 0;
    }
    .carousel-inner {
      .carousel-item {
        @media screen and (max-width: 600px) {
          // height: 200px;
          .banner-image {
            object-fit: cover;
          }
          h1 {
            display: none;
          }
        }
      }
      .first-slide {
        &.carousel-caption {
          text-align: left;
          bottom: calc(50% - 90px);
          width: 400px;
          left: 20%;
          h1 {
            color: #2d2d2d;
            font-weight: bold;
            @media screen and (max-width: 767px) {
              font-size: 25px;
            }
          }
          img.google-play {
            margin-top: 7px;
            @media screen and (max-width: 767px) {
              width: 100px;
            }
          }
          @media screen and (max-width: 767px) {
            left: 0;
            right: 0;
            margin: 0 auto;
            text-align: center;
            bottom: 20px;
            width: 300px;
          }
        }
      }
      .second-slide {
        &.carousel-caption {
          bottom: 50px;
          width: auto;
          margin: 0 auto;
          h1 {
            color: #2d2d2d;
            font-weight: bold;
            @media screen and (max-width: 950px) {
              font-size: 25px;
              margin-bottom: 0;
            }
          }
          img.google-play {
            margin-top: 7px;
            @media screen and (max-width: 767px) {
              width: 100px;
            }
          }
          p {
            color: #2d2d2d;
            @media screen and (max-width: 767px) {
              font-size: 14px;
              margin-bottom: 0;
            }
          }

          @media screen and (max-width: 950px) {
            bottom: 20px;
          }
        }
      }
      .third-slide {
        &.carousel-caption {
          bottom: 50px;
          width: auto;
          margin: 0 auto;
          @media screen and (max-width: 767px) {
            bottom: 20px;
          }
          img {
            @media screen and (max-width: 767px) {
              width: 100px;
            }
          }
        }
      }
    }
  }
  .carousel-indicators {
    li {
      width: 14px;
      height: 14px;
      border-radius: 50%;
      opacity: 1;
    }
    .active {
      background-color: #756aa9;
      opacity: 1;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 70px;
  }
}

#why-mart {
  background-color: #f3f3f5;
  padding: 50px 0px;
  @media screen and (max-width: 767px) {
    padding: 30px 0px;
  }
  p {
    font-size: 13px;
    font-weight: 600;
  }
  img {
    width: 70px;
    margin-bottom: 8px;
  }
}

.achievements {
  padding: 40px 0px;
  @media screen and (min-width: 768px) {
    padding: 40px 0px 100px 0px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 0px;
  }
  img {
    width: 50px;
  }
  .AchievementItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    span {
      border: 2px solid #514d6a;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 110px;
      width: 110px;
      margin-bottom: 20px;
      @media screen and (max-width: 767px) {
        height: 90px;
        width: 90px;
        margin-bottom: 10px;
        svg {
          width: 1em;
        }
      }
    }
    p {
      font-size: 16px;
    }

    @media all and (max-width: 767px) {
      margin-bottom: 10px;
      h4 {
        font-size: 1.3em;
      }
    }

    &:last-child {
      @media all and (max-width: 767px) {
        margin-bottom: 0px;
      }
    }
  }
  .row {
    display: flex;
    justify-content: space-around;
  }
}

.headingBorder {
  height: 2px;
  width: 70px;
  background-color: #a29ac8;
  margin: auto;
  margin-bottom: 50px;
  @media screen and (max-width: 767px) {
    margin-bottom: 35px;
  }
}

#products {
  position: relative;
  @media screen and (min-width: 768px) {
    padding: 40px 0px 60px 0px;
  }
  @media screen and (max-width: 767px) {
    padding: 30px 20px 30px 20px;
  }
  .tab-content {
    margin-top: 40px;
  }
  .nav-tabs {
    border: none;
    .nav-link {
      font-size: 22px;
      font-weight: bold;
    }
    .nav-link {
      color: #646464;
      &.active {
        position: relative;
        &::before {
          content: "";
          position: absolute;
          height: 3px;
          width: 70px;
          background-color: #de099e;
          bottom: -2px;
          margin: 0 auto;
          left: 0;
          right: 0;
        }
      }
    }
    .nav-item {
      border: none;
      &:first-child {
        margin-right: 60px;
      }
    }
  }
  .CategoryWrapper {
    list-style-type: none;
    li {
      min-width: 220px;
      margin: 50px auto 0px auto;

      @media screen and (max-width: 767px) {
        padding: 20px;
        margin: 0 auto;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 20px 0px 0px 0px;
      margin-bottom: 0px;
    }
    .CategoryItem img {
      width: 80px;
    }

    .CategoryItem-des {
      margin-top: 30px;
      font-size: 16px;
      font-weight: bold;
      color: #ffffff;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
    }
  }
  .owl-carousel {
    .owl-stage-outer {
      .owl-stage {
        .owl-item {
          .item {
            background: #beb8d9;
            min-height: 250px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            border-radius: 3px;
            h6 {
              font-size: 16px;
              font-weight: 600;
              margin-bottom: 0;
              margin-top: 18px;
              @media screen and (max-width: 767px) {
                font-size: 15px;
              }
            }
            img {
              width: 100px;
            }
          }
        }
      }
    }
  }

  #uncontrolled-tab-example-tabpane-Categories {
    background: linear-gradient(
      180deg,
      rgba(151, 101, 202, 1) 19%,
      rgba(94, 80, 150, 1) 100%
    );
    border-radius: 15px;
    padding-bottom: 15px;
  }
  #uncontrolled-tab-example-tab-Products.active {
    position: relative;
    &::after {
      content: "";
      width: 0px;
      height: 0px;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      border-right: 25px solid #9966cc;
      position: absolute;
      left: -20px;
      top: -10px;
      transform: rotate(37deg);
      border-radius: 3px;
    }
  }
}
/*****How it works****/
#how-it-works {
  background-color: #f1f2f5;
  padding: 20px 0px;
  position: relative;
  margin-bottom: 100px;
  @media screen and (max-width: 767px) {
    padding: 30px 0px;
  }
  .slider-section {
    display: flex;
    align-items: flex-end;
    .carousel-indicators {
      li {
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background-color: #796cb5;
      }
      .active {
        background-color: #756aa9;
        opacity: 1;
      }
      @media screen and (min-width: 768px) {
        bottom: -50px;
      }
    }
    .carousel-inner {
      .carousel-item {
        width: 70%;
        z-index: 1;
      }
    }
    @media screen and (min-width: 768px) {
      &::after {
        content: "";
        width: 0px;
        height: 0px;
        border-top: 100px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 100px solid #9966cc;
        position: absolute;
        left: 0px;
        bottom: -63px;
        transform: rotate(89deg);
        border-radius: 5px;
        z-index: 10;
      }
    }
  }
  @media screen and (min-width: 768px) {
    padding: 30px 0px 60px 0px;
  }

  .work-description-section {
    background-image: url(./images/bg2.png);
    position: relative;
    z-index: 0;
    background-size: contain;
    @media screen and (min-width: 768px) {
      padding: 0;
    }
    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #f1f2f5;
      opacity: 0.9;
      z-index: -1;
      width: 100%;
    }
    @media screen and (min-width: 768px) {
      &::after {
        content: "";
        width: 0px;
        height: 0px;
        border-top: 100px solid transparent;
        border-bottom: 5px solid transparent;
        border-right: 100px solid #9966cc;
        position: absolute;
        right: -3px;
        top: -33px;
        transform: rotate(-90deg);
        border-radius: 5px;
      }
    }
    .list-wrapper {
      padding: 60px 20px 0px 60px;
      @media screen and (max-width: 767px) {
        padding: 20px 20px 0px 20px;
      }
      h3 {
        margin-bottom: 50px;
        position: relative;
        display: inline-block;
        &::after {
          content: "";
          height: 2px;
          width: 60px;
          background-color: #aaa3cc;
          position: absolute;
          left: 31%;
          bottom: -10px;
        }
      }
    }

    .process-wrapper {
      list-style: none;
      margin-bottom: 0;
      padding: 0;
      li {
        display: flex;
        margin-bottom: 10px;
        span {
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background-color: #514d6a;
          display: table;
          vertical-align: middle;
          text-align: center;
          line-height: 60px;
          flex: 0 0 60px;
          svg {
            font-size: 1.3em;
          }
        }
        .description {
          display: inline-block;
          padding-left: 15px;
          h5 {
            font-size: 16px;
            font-weight: bold;
          }
          p {
            font-size: 14px;
          }
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
    }
  }
}

/******end works*****/

/****contact-form****/
#contact {
  background-color: #433e5b;
  padding: 120px 0px;
  @media screen and (max-width: 767px) {
    padding: 40px 0px;
  }
  .ContactForm-Right {
    position: relative;
    .form-section {
      width: 100%;
      background-color: #f5eefe;
      border-radius: 20px;
      padding: 50px 30px;
      box-shadow: 0px 0px 1px #333333;
      .form-heading {
        color: #333333;
        font-weight: bold;
        font-size: 24px;
        margin-bottom: 15px;
      }
      input {
        width: 100%;
        padding: 5px 12px;
        border: 1px solid #b2aeb6;
        background-color: #faf6fe;
        border-radius: 10px;
        &::placeholder {
          color: #cbc9cd;
          font-size: 14px;
        }
      }
      button {
        background-color: #9990c5;
        color: #ffffff;
        border: none;
        padding: 8px 20px;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 13px;
        border-radius: 30px;
        width: 100px;
      }
      @media screen and (min-width: 768px) {
        position: absolute;
        top: -180px;
        right: 20%;
      }
      @media screen and (max-width: 767px) {
        margin-top: 30px;
      }
    }
  }
  .ContactForm-Left {
    h6 {
      color: #ffffff;
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
    }
    img {
      width: 120px;
    }
  }
}

.footer {
  padding: 60px 0px 40px 0px;
  background-color: #f5eefe;
  ul {
    list-style: none;
    padding: 0;
    li {
      color: #1d1d1d;
      font-weight: bold;
      font-size: 16px;
      @media screen and (max-width: 767px) {
        font-size: 15px;
      }
      a {
        color: #1d1d1d;
        text-decoration: none;
      }
    }
    &.social-icons {
      margin-top: 10px;
      li {
        display: inline-block;
        margin-right: 5px;
        img {
          width: 22px;
        }
      }
    }
  }
  .footerLogo {
    width: 100px;
    margin-bottom: 5px;
  }

  @media screen and (max-width: 767px) {
    padding: 20px 0px;
  }

  @media screen and (min-width: 768px) {
    .column-eq {
      margin-top: 28px;
    }
    .social-section {
      text-align: center;
    }
  }
}

.copyright {
  padding: 20px 0px;
  background-color: #433e5b;
  .copyright-text {
    color: #ffffff;
    font-weight: bold;
    font-size: 15px;
  }
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled,
.owl-carousel .owl-nav {
  display: block !important;
  .owl-prev {
    top: calc(50% - 24px);
    left: -40px;
    position: absolute;
    .fa-angle-left {
      &:before {
        color: #9990c5;
        font-size: 45px;
        @media screen and (max-width: 800px) {
          font-size: 35px;
        }
      }
    }
    &:hover {
      background-color: transparent;
    }
    @media screen and (max-width: 767px) {
      left: -20px;
    }
  }
  .owl-next {
    top: calc(50% - 24px);
    right: -40px;
    position: absolute;
    .fa-angle-right {
      &:before {
        color: #9990c5;
        font-size: 45px;
        @media screen and (max-width: 800px) {
          font-size: 35px;
        }
      }
    }
    &:hover {
      background-color: transparent;
    }
    @media screen and (max-width: 767px) {
      right: -20px;
    }
  }
}

.contentless-delivery {
  padding: 40px 0px;
}

.offers {
  padding: 40px 0px;
}

.pagenotfound {
  padding: 40px 0px;
  height: 100vh;
}

.sectionHeading {
  font-size: 2rem;
  font-weight: bold;
  @media screen and (max-width: 767px) {
    font-size: 1.75em;
  }
}
