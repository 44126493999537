.loadingContainer, .loading-cls {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    padding-top: 20px;
    padding-bottom: 20px;
}
.loadingContainer .hideVisibility {
    opacity: 0;
    visibility: hidden;
}
.loadingContainer .loading-icon {
    display: inline-flex;
    width: 14px;
    height: 14px;
    min-width: 14px;
    min-height: 14px;
    border: solid 2px transparent;
    border-top-color: #fff;
    border-left-color: #fff;
    border-radius: 50%;
    animation: pace-spinner 400ms linear infinite;
}
@keyframes pace-spinner {
    0% {
        transform: rotate(0deg);
        transform: rotate(0deg);
   }
    100% {
        transform: rotate(360deg);
        transform: rotate(360deg);
   }
}
.loadingContainer .btn {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    box-shadow: 0 1px 0 0 rgba(171, 181, 189, 0.29);
    white-space: nowrap;
    font-weight: 600;
    min-height: 34px;
    touch-action: manipulation;
    cursor: pointer;
    border: 1px solid transparent;
    padding: 4px 12px;
    font-size: 14px;
    line-height: 1.43;
    border-radius: 2px;
    transition: all 200ms ease;
    user-select: none;
    outline: none;
}
.loadingContainer .btn .loading-icon {
    margin-left: 10px;
}
.loadingContainer .btn-default {
    color: #0070d2;
    background-color: #fff;
    border-color: #a8b7c7;
}
.loadingContainer .btn-default:focus, .loadingContainer .btn-default.focus, .loadingContainer .btn-default:active, .loadingContainer .btn-default.active {
    color: #0070d2;
    background-color: #f4f6fc;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
}
.loadingContainer .btn-default:hover {
    color: #198cf0;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.20);
}
.loadingContainer .btn-default.disabled:hover, .loadingContainer .btn-default[disabled]:hover, .loadingContainer fieldset[disabled] .btn-default:hover, .loadingContainer .btn-default.disabled:focus, .loadingContainer .btn-default[disabled]:focus, .loadingContainer fieldset[disabled] .btn-default:focus, .loadingContainer .btn-default.disabled.focus, .loadingContainer .btn-default[disabled].focus, .loadingContainer fieldset[disabled] .btn-default.focus, .loadingContainer .btn-default.disabled:active, .loadingContainer .btn-default[disabled]:active, .loadingContainer fieldset[disabled] .btn-default:active, .loadingContainer .btn-default.disabled.active, .loadingContainer .btn-default[disabled].active, .loadingContainer fieldset[disabled] .btn-default.active {
    color: rgba(0, 112, 210, 0.4);
    background-color: #f9fafb;
    border-color: #dfe3e8;
}
.loadingContainer .btn-default .loading-icon {
    border-top-color: #0070d2;
    border-left-color: #0070d2;
}
.loadingContainer {
    text-align: center;
}
.loadingContainer .loading-icon {
    border-top-color: #f75f3a !important;
    border-left-color: #f75f3a !important;
}
.loadingContainer .loading-icon.lg {
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
}
